body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 300px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 900px) {
  html {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 20px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: #eff2f5;
}

@media print {
  html,
  body {
    height: initial !important;
    width: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .page-break {
    display: block;
    page-break-before: auto;
    page-break-inside: avoid;
    break-before: auto !important;
    break-inside: avoid !important;
  }
  .force-page-break {
    page-break-before: always;
    break-before: always !important;
    page-break-inside: avoid;
    break-inside: avoid !important;
  }
  .soft-page-break {
    page-break-before: auto;
    page-break-inside: avoid;
    break-before: auto !important;
    break-inside: avoid !important;
  }
  .padding {
    padding-top: 2rem;
  }
  .hide-for-print,
  .hide-for-analyst * {
    display: none !important;
  }
  * {
    overflow: visible;
    font-size: 10px;
    transition: none !important;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #edecec;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c4d7db;
}

.page-break-after {
  break-after: always;
}
